import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Anchor,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  Space,
  Switch,
  Tabs,
  Text,
  ThemeIcon,
} from "@mantine/core";
import {
  useParams,
  useSearchParams,
  Route,
  Routes,
  Link,
} from "react-router-dom";
import { IconForms, IconQrcode, IconExternalLink } from "@tabler/icons-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import {
  EntitySubtitles,
  ErrorView,
  MicrositeSettings,
  QrCodeGenerator,
  PageTitle,
  Subnav,
} from "@components/shared";
import {
  EffortAddonManagement,
  EffortAlerts,
  EffortAssets,
  EffortAwards,
  EffortCampaignManagement,
  EffortCsvDownload,
  EffortForm,
  EffortLocationJoinList,
  EffortSettings,
  EffortLinks,
  EffortReadOnly,
  EffortStatus,
  EffortWizardStatus,
  MultiSweepsEffortAwards,
} from "@components/Effort";
import { ContestKeywordEffortAssociate } from "@components/Contest";
import EffortBuilder from "@components/EffortBuilder";
import { KeywordManagement } from "@components/Keyword";
import { LocationAssociate } from "@components/Location";
import { EntityConfigSettings } from "@components/EntityConfig";
import { GeospotAssignment } from "@components/Geospot";
import { EntityEmailTemplates } from "@components/EmailServer";

import { effortRecurringEngagement } from "@components/Effort/helpers";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

export default function EffortDetail() {
  // const [error, setError] = useState(null);
  // const [info, setInfo] = useState(null);
  const queryClient = useQueryClient();
  const [showWizard, setShowWizard] = useState(true);

  const params = useParams();
  const [searchParams] = useSearchParams();
  const { id } = params;
  const queryKey = `efforts${id}`;

  const managerInfo = useSelector((state) => state.manager);
  const isLocationManager =
    managerInfo && managerInfo.location_id ? true : false;
  const isOrgManager =
    managerInfo && managerInfo.organization_id ? true : false;

  const isManager = managerInfo;

  useEffect(() => {
    // setInfo(null);
    fetchData();
  }, [id]);

  const fetchData = () => queryClient.invalidateQueries([queryKey]);

  const {
    failureReason: error,
    data: info,
    isFetching,
  } = useQuery({
    queryKey: [queryKey],
    queryFn: async () =>
      axios
        .get(`/efforts/${id}/`)
        .then(({ data }) => {
          const res = data.response[0];
          if (
            res.wizard_settings.show_quick_settings === false ||
            JSON.stringify(res.wizard_settings) === "{}" ||
            searchParams.get("quickSettings") === "false"
          ) {
            setShowWizard(false);
          }
          return res;
        })
        .catch((err) => {
          throw err;
        }),
  });

  // function fetchData() {
  //   axios
  //     .get(`/efforts/${id}/`)
  //     .then(({ data }) => {
  //       setInfo(data.response[0]);
  //       if (
  //         data.response[0].wizard_settings.show_quick_settings === false ||
  //         JSON.stringify(data.response[0].wizard_settings) === "{}" ||
  //         searchParams.get("quickSettings") === "false"
  //       ) {
  //         setShowWizard(false);
  //       }
  //     })
  //     .catch((err) => {
  //       setError(err);
  //       setInfo(null);
  //     });
  // }

  const fetchAlerts = () =>
    queryClient.invalidateQueries([`efforts${id}Alerts`]);

  const fetchCheckupAndAlerts = () =>
    queryClient.invalidateQueries([
      `efforts${id}Alerts`,
      `efforts${id}Checkup`,
    ]);

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  const qrCodeUrl = `${import.meta.env.VITE_DOMAIN}/entry/${
    info.microsite_url
  }`;

  const { wizard_settings: wizardSettings, wizard_status: wizardStatus } = info;
  const wizardComplete = wizardStatus.wizard_complete;
  const hasWizardSettings = JSON.stringify(wizardSettings) !== "{}";
  const isPrizepool = info.variety === 2;
  const isAuction = info.variety === 3;
  const isLocationEffort = info.location_id ? true : false;

  const showKeywords =
    info.recurring_engagement === effortRecurringEngagement.true ||
    (wizardSettings && wizardSettings.show_keywords);

  const EffortContestKeywords = () => (
    <ContestKeywordEffortAssociate
      effortId={id}
      locationId={info.location_id}
      organizationId={info.organization_id}
      smsRequired={wizardSettings.keyword_sms_required}
    />
  );

  const WizardToggle = () => (
    <React.Fragment>
      <Space mt="sm" />
      <Switch
        label="Quick Settings"
        checked={showWizard}
        onChange={() => setShowWizard(!showWizard)}
        mb="lg"
      />
    </React.Fragment>
  );

  const EffortTitle = () => {
    return (
      <React.Fragment>
        <PageTitle
          title={info.title}
          subtitle="Campaign Effort"
          icon={entityIcon.effort()}
          iconColor={entityColor.effort}
          style={{ marginBottom: "0" }}
        />
        <EntitySubtitles
          info={{
            ...info,
            campaign_id: null,
          }}
        />
        {info.points_keyword_hash && (
          <Flex>
            <ThemeIcon
              mr="xs"
              size="sm"
              variant="light"
              color={entityColor.keyword}
            >
              {entityIcon.keyword()}
            </ThemeIcon>
            <Text size="sm">
              <Anchor
                component={Link}
                target="_blank"
                to={`/keywords/${info.points_keyword_hash.id}`}
                style={{
                  color: "inherit",
                }}
              >
                {info.points_keyword_hash.name}
              </Anchor>
            </Text>
          </Flex>
        )}
        {info.contest_hash && (
          <Flex>
            <ThemeIcon
              mr="xs"
              size="sm"
              variant="light"
              color={entityColor.contest}
            >
              {entityIcon.contest()}
            </ThemeIcon>
            <Text size="sm">
              <Anchor
                component={Link}
                target="_blank"
                to={`/contests/${info.contest_hash.id}`}
                style={{
                  color: "inherit",
                }}
              >
                {info.contest_hash.name}
              </Anchor>
            </Text>
          </Flex>
        )}
        {info.edit && (
          <EffortCampaignManagement
            campaignId={info.campaign_id}
            campaignName={info.campaign_name}
            effortId={id}
            fetchData={() => {
              fetchData();
              fetchCheckupAndAlerts();
            }}
          />
        )}
      </React.Fragment>
    );
  };

  if (info.edit === false)
    return (
      <React.Fragment>
        <PageTitle
          title={info.title}
          subtitle="Effort"
          icon={entityIcon.effort()}
          iconColor={entityColor.effort}
        />
        <EffortReadOnly
          effortInfo={{
            ...info,
            id,
          }}
        />
      </React.Fragment>
    );

  if (!wizardComplete || showWizard) {
    return (
      <div>
        <EffortTitle />
        {wizardComplete ? <WizardToggle /> : <Space mt="sm" />}
        {wizardComplete && (
          <EffortAlerts effortId={id} setShowWizard={(e) => setShowWizard(e)} />
        )}
        {wizardComplete &&
          showKeywords &&
          info.recurring_engagement === effortRecurringEngagement.true && (
            <Card mb="sm">
              <Text fw={600} mb="xs">
                Keywords
              </Text>
              <EffortContestKeywords />
            </Card>
          )}
        <EffortWizardStatus
          id={id}
          campaignId={info.campaign_id}
          contestId={info.contest_hash ? info.contest_hash.id : null}
          effortVariety={info.variety}
          effortName={info.title}
          fetchData={fetchData}
          locationId={info.location_id}
          organizationId={info.organization_id}
          pointSettings={info.points_settings}
          status={info.status}
          isRecurring={info.recurring_engagement === 1}
          wizardComplete={wizardComplete}
          wizardSettings={wizardSettings}
          wizardStatus={wizardStatus}
          effortDates={
            info.start_date && info.end_date
              ? {
                  start_date: info.start_date,
                  start_time: info.start_time,
                  start_date_formatted: info.start_date_formatted,
                  end_date: info.end_date,
                  end_date_formatted: info.end_date_formatted,
                  end_time: info.end_time,
                }
              : null
          }
        />
      </div>
    );
  }

  const links = [
    {
      text: "Edit",
      to: `/efforts/${id}`,
    },
    {
      text: "Builder",
      to: `/efforts/${id}/builder`,
      trigger: "show_builder",
    },
    {
      text: "Assets",
      to: `/efforts/${id}/assets`,
      trigger: "show_assets",
    },
    {
      text: "Look & Feel",
      to: `/efforts/${id}/config`,
      trigger: "show_look_feel",
    },
    // { text: "Other", to: `/efforts/${id}/other` },
    {
      text: "Keywords",
      to: `/efforts/${id}/keywords`,
      trigger: "show_keywords",
    },
    {
      text: "Awards",
      to: `/efforts/${id}/awards`,
      trigger: "show_awards",
    },
    { text: "Locations", to: `/efforts/${id}/locations` },
    {
      text: "Geo-Spots",
      to: `/efforts/${id}/geospots`,
      trigger: "show_geospots",
    },
    {
      text: "Email",
      to: `/efforts/${id}/email`,
      trigger: "show_email",
    },
    {
      text: "Visible Efforts",
      to: `/efforts/${id}/addons`,
    },
    {
      text: "Settings",
      to: `/efforts/${id}/settings`,
    },
  ]
    .filter((f) => (!info.location_id ? !["Geo-Spots"].includes(f.text) : true))
    .filter((f) => (wizardSettings[f.trigger] === false ? false : true))
    .filter((f) =>
      isLocationManager || info.location_id
        ? !["Locations"].includes(f.text)
        : true
    )
    .filter((f) => (isManager ? !["Email"].includes(f.text) : true))
    .filter((f) => (!showKeywords ? !["Keywords"].includes(f.text) : true))
    .filter((f) =>
      wizardSettings.microsite === false ||
      wizardSettings.show_look_feel === false
        ? !["Look & Feel"].includes(f.text)
        : true
    )
    .filter((f) =>
      wizardSettings.show_settings === false
        ? !["Settings"].includes(f.text)
        : true
    );

  const showMicrosite = !hasWizardSettings
    ? true
    : wizardSettings.microsite === false ||
      wizardSettings.show_look_feel === false
    ? false
    : true;

  const showStatusButtons =
    isAuction ||
    isPrizepool ||
    (info.variety === 4 && info.registration_method !== "form");

  const showSubnav =
    (info.microsite_url && showMicrosite) ||
    (!managerInfo && wizardSettings.show_csv !== false);

  return (
    <React.Fragment>
      <EffortTitle />
      {wizardComplete &&
        wizardSettings.show_quick_settings !== false &&
        JSON.stringify(wizardSettings) !== "{}" && <WizardToggle />}
      {links.length > 1 ? (
        <Flex mt="sm">
          <Subnav buttonColor={entityColor.effort} links={links} />
        </Flex>
      ) : (
        <Space mt="lg" />
      )}
      {showSubnav && (
        <Flex gap="sm" mb="lg">
          {info.microsite_url && showMicrosite && (
            <Button
              component="a"
              variant="light"
              radius="xl"
              rightSection={<IconExternalLink size={16} />}
              color="gray"
              size="xs"
              target="_blank"
              href={`${import.meta.env.VITE_DOMAIN}/entry/${
                info.microsite_url
              }`}
            >
              Preview Effort
            </Button>
          )}
          {!managerInfo && wizardSettings.show_csv !== false && (
            <EffortCsvDownload effortId={id} />
          )}
        </Flex>
      )}
      {wizardComplete && (
        <EffortAlerts effortId={id} setShowWizard={(e) => setShowWizard(e)} />
      )}
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Grid>
              <Grid.Col
                span={{
                  base: 12,
                  md:
                    wizardSettings.show_quick_settings !== false ||
                    showStatusButtons
                      ? 9
                      : 12,
                }}
              >
                <Card>
                  <Tabs defaultValue="form">
                    {(wizardSettings.microsite || !hasWizardSettings) && (
                      <Tabs.List mb="lg">
                        <Tabs.Tab icon={<IconForms />} value="form">
                          Form
                        </Tabs.Tab>
                        <Tabs.Tab icon={<IconQrcode />} value="qr">
                          QR Code
                        </Tabs.Tab>
                      </Tabs.List>
                    )}
                    <Tabs.Panel value="form">
                      <div>
                        <EffortForm
                          allowManagerEdit={info.allow_manager_edit}
                          apiAppEnum={info.api_app_enum}
                          apiContext={info.api_context}
                          apiProgram={info.api_program}
                          awardType={info.award_type}
                          endDate={info.end_date}
                          endTime={info.end_time}
                          id={id}
                          micrositeGallery={info.microsite_gallery}
                          internalName={info.internal_name}
                          onSuccess={fetchData}
                          pixelCode={info.pixel_code}
                          recurringEngagement={info.recurring_engagement}
                          sendFromEmail={info.send_from_email}
                          sendRegistrationEmail={info.send_registration_email}
                          shareData={info.share_data}
                          showShareData={
                            !isLocationManager && info.organization_id
                              ? true
                              : false
                          }
                          startDate={info.start_date}
                          startTime={info.start_time}
                          timezone={info.timezone}
                          title={info.title}
                          thirdPartyApiEnabled={info.third_party_api_enabled}
                          thirdPartyCampaignCode={
                            info.third_party_campaign_code
                          }
                          variety={info.variety}
                          wizardSettings={wizardSettings}
                        />
                        {info.recurring_engagement !==
                          effortRecurringEngagement.false && (
                          <React.Fragment>
                            <Divider my="sm" />
                            <EntityConfigSettings
                              effortId={id}
                              useKeywords={info.use_keywords}
                              useCheckins={info.use_checkins}
                              onUpdate={fetchData}
                            />
                          </React.Fragment>
                        )}
                      </div>
                    </Tabs.Panel>
                    <Tabs.Panel value="qr">
                      {isLocationEffort ? (
                        <QrCodeGenerator text={qrCodeUrl} />
                      ) : (
                        <>
                          {isOrgManager || !managerInfo ? (
                            <>
                              <EffortLocationJoinList effortId={id} />
                            </>
                          ) : (
                            <>
                              <QrCodeGenerator text={qrCodeUrl} />
                            </>
                          )}
                        </>
                      )}
                      {/* {info.microsite_gallery ? (
                        <>
                          {(isOrgManager || !managerInfo) && (
                            <Text fw={600} color="red" mb="sm">
                              *The QR code below will lead to the Gallery, not
                              the Effort.
                            </Text>
                          )}
                          <QrCodeGenerator text={qrCodeUrl} />
                        </>
                      ) : (
                        <Text>
                          This effort will not show up in the gallery.
                        </Text>
                      )} */}
                    </Tabs.Panel>
                  </Tabs>
                </Card>
              </Grid.Col>
              {(wizardSettings.show_quick_settings !== false ||
                showStatusButtons) && (
                <Grid.Col span={{ base: 12, md: 3 }}>
                  <EffortStatus
                    statusOnly={showStatusButtons}
                    effortId={id}
                    fetchData={() => {
                      // fetchData();
                      fetchAlerts();
                    }}
                  />
                </Grid.Col>
              )}
            </Grid>
          }
        />
        <Route
          path="builder"
          element={
            <div>
              <EffortBuilder
                postmarkTemplateNames={info.postmark_template_names}
                effortId={id}
                effortName={info.title}
                fetchEffortData={() => {
                  fetchCheckupAndAlerts();
                }}
                language={info.language}
              />
            </div>
          }
        />
        <Route
          path="assets"
          element={
            <div>
              <EffortAssets effortId={id} effortName={info.title} />
            </div>
          }
        />
        <Route
          path="keywords"
          element={
            <React.Fragment>
              {/* <Card>
                <EffortContestKeywords />
              </Card> */}
              <KeywordManagement
                minStartDate={info.start_date}
                maxEndDate={info.end_date}
                effortId={info.contest_hash ? null : id}
                contestId={info.contest_hash ? info.contest_hash.id : null}
                // showCreate={info.points_keyword_hash ? false : true}
                showCreate={false}
                additionalCreateData={{
                  location_id: info.location_id,
                  organization_id: info.organization_id,
                }}
                // locationId={info.location_id}
                // organizationId={info.organization_id}
              />
            </React.Fragment>
          }
        />
        <Route
          path="awards"
          element={
            <React.Fragment>
              {info.variety === 5 ? (
                <MultiSweepsEffortAwards
                  effortName={info.title}
                  effortId={id}
                  locationId={info.location_id}
                  organizationId={info.organization_id}
                  fetchData={() => {
                    fetchAlerts();
                  }}
                />
              ) : (
                <EffortAwards
                  awardType={info.award_type}
                  effortId={id}
                  endDate={info.end_date}
                  fetchEffort={fetchData}
                  pointSettings={info.points_settings}
                  pointsOnly={wizardSettings.point_award_required === true}
                  recurringEngagement={info.recurring_engagement}
                  showVarietyFilters={
                    hasWizardSettings && ![2, 3].includes(info.variety)
                  }
                  wizardSettings={wizardSettings}
                />
              )}
            </React.Fragment>
          }
        />
        {!isLocationManager && (
          <Route
            path="locations"
            element={
              <React.Fragment>
                <LocationAssociate
                  effortId={id}
                  disabledIds={info.active_location_ids}
                  selectReqData={{
                    status: 1,
                    campaign_effort_id: id,
                    organization_id: info.organization_id,
                    context: "add_locations_to_effort",
                  }}
                  fetchData={fetchData}
                  bulkData={{
                    show_location_variety_filter: true,
                    download_request_data: {
                      // organization_id: info.organization_id,
                      context: "campaign_effort_locations",
                      campaign_effort_id: id,
                    },
                    upload_request_data: {
                      campaign_effort_id: id,
                      context: "add_effort_loc",
                    },
                  }}
                />
              </React.Fragment>
            }
          />
        )}
        <Route
          path="email"
          element={
            <React.Fragment>
              <EntityEmailTemplates
                postmarkTemplateNames={info.postmark_template_names}
                effortId={id}
                templates={[
                  {
                    name: "Verify Email",
                    field_name: "verify-email",
                    value: info.verification_template,
                  },
                  {
                    name: "Registration Email",
                    field_name: "registration",
                    value: info.registration_template,
                  },
                ]}
                fetchData={fetchData}
              />
            </React.Fragment>
          }
        />
        {wizardSettings.microsite !== false && (
          <Route
            path="config"
            element={
              <Grid>
                <Grid.Col span={{ base: 12, md: 9 }}>
                  <div>
                    <MicrositeSettings
                      // campaignId={info.campaign_id}
                      effortId={id}
                      locationId={info.location_id}
                      onUpdate={() => {
                        fetchCheckupAndAlerts();
                      }}
                    />
                  </div>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 3 }}>
                  <EffortStatus
                    effortId={id}
                    fetchData={() => {
                      // fetchData();
                      fetchAlerts();
                    }}
                  />
                </Grid.Col>
              </Grid>
            }
          />
        )}
        {info.location_id && (
          <Route
            path="geospots"
            element={
              <React.Fragment>
                <GeospotAssignment
                  effortId={id}
                  locationId={info.location_id}
                />
              </React.Fragment>
            }
          />
        )}
        <Route
          path="addons"
          element={
            <React.Fragment>
              <EffortAddonManagement effortId={id} />
            </React.Fragment>
          }
        />
        <Route
          path="settings"
          element={
            <Card>
              <EffortSettings
                beginDate={info.start_date}
                effortId={id}
                endDate={info.end_date}
                fetchEffort={fetchData}
                wizardSettings={wizardSettings}
              />
            </Card>
          }
        />
      </Routes>
    </React.Fragment>
  );
}

export function Edit() {
  return <EffortForm onSuccess={fetchData} id={id} title={info.title} />;
}
