import React from "react";
import { Text } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

import ViewTitle from "./ViewTitle";
import { iconSize } from "./helpers";
import { effortAwardType } from "@components/Effort/helpers";

export default function EffortRegistered({ registrationInfo }) {
  const { t } = useTranslation();

  if (!registrationInfo) return null;

  return (
    <div>
      <ViewTitle
        title={`${registrationInfo.name}`}
        subtitle={t("effort_already_registered")}
        icon={<IconCheck size={iconSize} />}
      />
      <Text size="xl" mb="xl" align="center" c="white">
        {t("completed")}{" "}
        {new Date(registrationInfo.date_submitted).toLocaleString()}
      </Text>
      <Text size="xl" mb="xl" align="center" c="white">
        {registrationInfo.award_type === effortAwardType.contest_entries
          ? t("non_recurring_registration_complete")
          : `${t("points")} ${t("gained")}: ${registrationInfo.award_value}`}
      </Text>
    </div>
  );
}
