import React, { useState, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import toast from "react-hot-toast";
import {
  ActionIcon,
  Group,
  Grid,
  Flex,
  Text,
  HoverCard,
  Modal,
  List,
} from "@mantine/core";
import {
  IconTrash,
  IconBolt,
  IconCaretDown,
  IconPencil,
} from "@tabler/icons-react";

import FieldForm from "./EffortFieldForm";
import { SelectItemManagement } from "@components/Effort";

const FieldItem = ({
  fieldFormState,
  onRemoveSuccess,
  onUpdate,
  postmarkTemplateNames,
  effortId,
  eiWithTriggerId,
}) => {
  const [editing, setEditing] = useState(false);

  function onRemove() {
    if (!fieldFormState.engagement_item_id)
      return onRemoveSuccess(fieldFormState.uuid);

    axios
      .post(`/engagement-items/${fieldFormState.engagement_item_id}/remove/`, {
        engagement_item_id: fieldFormState.engagement_item_id,
      })
      .then(() => {
        onRemoveSuccess(fieldFormState.uuid);
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  const showTriggerManagement =
    (!eiWithTriggerId &&
      postmarkTemplateNames &&
      postmarkTemplateNames.length > 0) ||
    eiWithTriggerId === fieldFormState.engagement_item_id;

  return (
    <StyledFieldItem>
      <Modal
        opened={editing}
        onClose={() => setEditing(false)}
        title="Edit Field"
      >
        <FieldForm
          formState={fieldFormState}
          engagementItemId={fieldFormState.engagement_item_id}
          onSubmit={(e) => {
            onUpdate(e);
            setEditing(false);
          }}
        />
      </Modal>
      <React.Fragment>
        <Flex gap="xl">
          <Text
            fw={600}
            style={{
              flexGrow: 1,
            }}
          >
            {fieldFormState.label}
            {fieldFormState.required && <React.Fragment>*</React.Fragment>}
          </Text>
          <Flex gap="xs">
            <ActionIcon
              variant="light"
              onClick={() => setEditing(!editing)}
              color="yellow"
              radius="xl"
            >
              <IconPencil size={16} />
            </ActionIcon>
            {fieldFormState.type === "select" &&
              fieldFormState.options.length > 0 && (
                <>
                  {showTriggerManagement && (
                    <SelectItemManagement
                      field={fieldFormState}
                      effortId={effortId}
                    />
                  )}
                  <HoverCard>
                    <HoverCard.Target>
                      <ActionIcon variant="light" color="gray">
                        <IconCaretDown size={16} />
                      </ActionIcon>
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                      <List>
                        {[...fieldFormState.options]
                          .slice(0, 10)
                          .map((m, i) => (
                            <List.Item key={i + 1}>
                              {m.text}
                              {m.error_string && (
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    marginLeft: "5px",
                                  }}
                                >
                                  !
                                </span>
                              )}
                            </List.Item>
                          ))}
                      </List>
                    </HoverCard.Dropdown>
                  </HoverCard>
                </>
              )}
            {![
              "first_name",
              "last_name",
              "mobile_phone",
              "email",
              "zip",
              "marketing_opt_in",
            ].includes(fieldFormState.db_property) && (
              <ActionIcon
                variant="light"
                color="red"
                onClick={() => onRemove()}
                radius="xl"
              >
                <IconTrash size={16} />
              </ActionIcon>
            )}
          </Flex>
        </Flex>
      </React.Fragment>
    </StyledFieldItem>
  );
};

export default FieldItem;

const StyledFieldItem = styled.div`
  padding: 1em 0;

  & + & {
    border-top: 1px solid #eee;
  }

  ul.field-item-options {
    max-height: 150px;
    overflow-y: auto;
  }
`;
